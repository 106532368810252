import { render, staticRenderFns } from "./ImpressumContent.vue?vue&type=template&id=3456fa96&scoped=true&"
import script from "./ImpressumContent.vue?vue&type=script&lang=ts&"
export * from "./ImpressumContent.vue?vue&type=script&lang=ts&"
import style0 from "./ImpressumContent.vue?vue&type=style&index=0&id=3456fa96&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3456fa96",
  null
  
)

export default component.exports