



















































import HrefLink from '@/components/elements/HrefLink.vue';
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
             components: {HrefLink}
           })
export default class ImpressumContent extends Vue {
}
