

















import ImpressumContent from '@/components/contents/ImpressumContent.vue';
import Page from '@/components/elements/Page.vue';
import UndersideHeader from '@/components/elements/UndersideHeader.vue';
import BaseSite from '@/views/BaseSite.vue';
import { Component, Vue } from 'vue-property-decorator';
@Component({
             components: {
               ImpressumContent,
               UndersideHeader,
               Page
             },
           })
export default class Impressum extends BaseSite {
  private impressumPage: object = {menuActive:false};

}
